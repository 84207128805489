<template>
    <div>
        <SignUpForm />
    </div>
</template>

<script>
import SignUpForm from '@/components/SignUpForm.vue'
export default {
  components: {
    SignUpForm
  }
}
</script>
