<template>
    <div>
        <b-container>
            <section class="signup">
                <div class="sector">
                    <form :class="getDisabled ? 'disabled-element' : ''" @submit.prevent="signUp" class="login-form">
                        <h2 class="form-title">Sign up for Branding Pavilion</h2>
                        <p v-if="getError" class="custom-error"> {{ getError }} </p>
                        <p v-if="signUpForm.localError" class="custom-error">{{ signUpForm.localError }}</p>
                        <div class="form-element">
                            <label for="signUpName" class="form-label">Name</label>
                            <input :disabled="getDisabled" v-model.trim="signUpForm.signUpName" @blur="$v.signUpForm.signUpName.$touch()"  id="signUpName" class="form-input" type="text" placeholder="Enter your name" :class="[{'input-error' : $v.signUpForm.signUpName.$error }, getDisabled ? 'disabled-element' : '' ]"/>

                            <div v-if="$v.signUpForm.signUpName.$error">
                                <p v-if="!$v.signUpForm.signUpName.required" class="custom-error">Name is required</p>
                                <p v-else-if="!$v.signUpForm.signUpName.maxLength" class="custom-error">Name must not exceed 50 characters</p>
                            </div>
                        </div>
                        <div class="form-element">
                            <label for="signUpEmail" class="form-label">E-mail address</label>
                            <input :disabled="getDisabled" v-model.trim="signUpForm.signUpEmail" @blur="$v.signUpForm.signUpEmail.$touch()" id="signUpEmail" class="form-input" type="email" placeholder="Enter e-mail address" :class="[{'input-error' : $v.signUpForm.signUpEmail.$error }, getDisabled ? 'disabled-element' : '' ]"/>

                            <div v-if="$v.signUpForm.signUpEmail.$error">
                                <p v-if="!$v.signUpForm.signUpEmail.required" class="custom-error">E-mail address is required</p>
                                <p v-else-if="!$v.signUpForm.signUpEmail.email" class="custom-error">E-mail address is invalid</p>
                                <p v-else-if="!$v.signUpForm.signUpEmail.maxLength" class="custom-error">E-mail address must not exceed 50 characters</p>
                            </div>
                        </div>
                        <div class="form-element">
                            <label for="signUpPassword" class="form-label">Password</label>
                            <input :disabled="getDisabled" v-model.trim="signUpForm.signUpPassword" @blur="$v.signUpForm.signUpPassword.$touch()" id="signUpPassword" class="form-input" type="password" placeholder="Enter password" :class="[{'input-error' : $v.signUpForm.signUpPassword.$error }, getDisabled ? 'disabled-element' : '' ]" />

                            <div v-if="$v.signUpForm.signUpPassword.$error">
                                <p v-if="!$v.signUpForm.signUpPassword.required" class="custom-error">Password is required</p>
                                <p v-else-if="!$v.signUpForm.signUpPassword.minLength" class="custom-error"> Minimal password length is 8 characters</p>
                            </div>
                        </div>
                        <div class="form-element">
                            <label for="confirmSignUpPassword" class="form-label">Confirm password</label>
                            <input :disabled="getDisabled" v-model.trim="signUpForm.confirmSignUpPassword" @blur="$v.signUpForm.confirmSignUpPassword.$touch()" id="confirmSignUpPassword" class="form-input" type="password" placeholder="Enter password again" :class="[{'input-error' : $v.signUpForm.confirmSignUpPassword.$error }, getDisabled ? 'disabled-element' : '' ]" />

                            <div v-if="$v.signUpForm.confirmSignUpPassword.$error">
                                <p v-if="!$v.signUpForm.confirmSignUpPassword.confirmPassword" class="custom-error">Given passwords do not match</p>
                            </div>
                        </div>
                        <div class="form-element">
                            <p-check :disabled="getDisabled" v-model="signUpForm.termsConsent" class="p-svg p-curve p-tada" color="success">
                            <!-- svg path -->
                            <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                    style="stroke: white;fill:white"></path>
                            </svg>
                            I agree to the <router-link to="/terms" class="form-link form-link--medium">Terms of Service</router-link> and <router-link to="/privacy" class="form-link form-link--medium">Privacy Policy</router-link>
                            </p-check>
                            <div v-if="$v.signUpForm.termsConsent.$error">
                                <p v-if="!$v.signUpForm.termsConsent.acceptTermsConsent" class="custom-error">Consent is required</p>
                            </div>
                        </div>
                        <div class="form-element">
                            <div class="recaptcha">
                                <vue-recaptcha @verify="markRecaptchaAsVerified" sitekey="6Le7KpkUAAAAAEBVKrKjjFulH3zv9IHwfYdHDkCI"></vue-recaptcha>
                            </div>
                            <div v-if="$v.signUpForm.recaptcha.$error">
                                <p v-if="!$v.signUpForm.recaptcha.verifyRecaptchay" class="custom-error">reCAPTCHA is required</p>
                            </div>
                        </div>
                        <button :disabled="getDisabled" class="submit-button" type="submit">Create New Account</button>
                        <b-row>
                            <b-col cols="12">
                                <p class="form-text form-text--small">Already have account? <router-link to="/signin" class="form-link">Sign in</router-link></p>
                            </b-col>
                        </b-row>
                    </form>
                </div>
            </section>
        </b-container>
    </div>
</template>

<script>
import PrettyCheck from 'pretty-checkbox-vue/check'
import VueRecaptcha from 'vue-recaptcha'
import {
  email,
  required,
  minLength,
  maxLength,
  sameAs
} from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

export default {
  components: {
    'p-check': PrettyCheck,
    VueRecaptcha
  },
  data: function () {
    return {
      signUpForm: {
        signUpName: '',
        signUpEmail: '',
        signUpPassword: '',
        confirmSignUpPassword: '',
        termsConsent: false,
        recaptcha: false,
        localError: null
      }
    }
  },
  validations: {
    signUpForm: {
      signUpName: {
        required: required,
        maxLength: maxLength(50)
      },
      signUpEmail: {
        required: required,
        email: email,
        maxLength: maxLength(50)
      },
      signUpPassword: {
        required: required,
        minLength: minLength(8)
      },
      confirmSignUpPassword: {
        confirmPassword: sameAs('signUpPassword')
      },
      termsConsent: {
        acceptTermsConsent: sameAs(() => true)
      },
      recaptcha: {
        verifyRecaptchay: sameAs(() => true)
      }
    }
  },
  methods: {
    markRecaptchaAsVerified (response) {
      this.signUpForm.recaptcha = true
    },
    signUp () {
      this.$store.dispatch('setDisabled')
      this.$v.signUpForm.$touch()
      if (!this.$v.signUpForm.$invalid) {
        this.$store.dispatch('signUp', { name: this.signUpForm.signUpName, email: this.signUpForm.signUpEmail, password: this.signUpForm.signUpPassword })
          .then(() => {
            this.scrollToTop(500)
          })
          .catch(error => {
            this.signUpForm.localError = error.message
            this.$store.dispatch('clearDisabled')
          })
      } else {
        this.scrollToTop(500)
        this.$store.dispatch('clearDisabled')
      }
    },
    scrollToTop (scrollDuration) {
      var scrollStep = -window.scrollY / (scrollDuration / 15)
      var scrollInterval = setInterval(function () {
        if (window.scrollY != 0) {
          window.scrollBy(0, scrollStep)
        } else clearInterval(scrollInterval)
      }, 15)
    }
  },
  mounted () {
    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit&hl=en')
    document.head.appendChild(recaptchaScript)
  },
  computed: {
    ...mapGetters([
      'getError',
      'getDisabled'
    ])
  },
  beforeCreate () {
    this.$store.dispatch('clearError')
    this.$store.dispatch('clearDisabled')
  }
}
</script>
